import "./App.css";
import fromTheShadowz from "./images/fromtheshadowz.jpg";
import bitshadowz from "./images/bitshadowz.png";
import shadowz from "./images/shadowz.png";
import { motion } from "framer-motion";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import smTwitter from "./images/icon-twitter.svg";
import smDiscord from "./images/icon-discord.svg";
function App() {
  return (
    <div className="App">
      <div className="bg"></div>
      <div className="nav-bar">
        <motion.a
          animate={{
            opacity: 1,
            transition: { delay: 0.8 },
          }}
          whileHover={{
            scale: 1.02,
          }}
          whileTap={{ scale: 0.99 }}
          href="https://shadowzverse.io"
          target="_blank"
        >
          <div className="shadowzverse-logo"></div>
        </motion.a>
        <div className="menu-items">
          <motion.a
            animate={{
              opacity: 1,
              transition: { delay: 0.8 },
            }}
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{ scale: 0.99 }}
            className="menu-item menu-item-sm"
            href="https://twitter.com/SHADOWZVERSE"
            target="_blank"
          >
            <img
              className="menu-item-icon"
              src={smTwitter}
              alt="Southern Empire"
            />
          </motion.a>
          <motion.a
            animate={{
              opacity: 1,
              transition: { delay: 0.8 },
            }}
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{ scale: 0.99 }}
            className="menu-item menu-item-sm"
            href="https://discord.gg/southernempire"
            target="_blank"
          >
            <img
              className="menu-item-icon"
              src={smDiscord}
              alt="Southern Empire"
            />
          </motion.a>
        </div>
      </div>
      <div className="project-wrapper">
        <a
          className="project-link"
          href="https://shadowzverse.io/fromtheshadowzvol1"
          target="_blank"
        >
          <img className="project" src={fromTheShadowz} alt="" />
        </a>
        <a
          className="project-link"
          href="https://shadowzverse.io/bitshadowz"
          target="_blank"
        >
          <img className="project" src={bitshadowz} alt="" />
        </a>
        <a className="project-link" href="https://shadowz.art" target="_blank">
          <img className="project" src={shadowz} alt="" />
        </a>{" "}
        <div className="empty"></div>
      </div>
    </div>
  );
}

export default App;
